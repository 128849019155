"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectConversationQuota = exports.selectUserQuota = exports.selectActiveSubscriptionName = exports.selectActiveSubscription = void 0;
const selectActiveSubscription = (state) => (state.subscriptionPlan.subscription);
exports.selectActiveSubscription = selectActiveSubscription;
const selectActiveSubscriptionName = (state) => (state.subscriptionPlan.subscription?.plan);
exports.selectActiveSubscriptionName = selectActiveSubscriptionName;
const selectUserQuota = (state) => state.subscriptionPlan.quota;
exports.selectUserQuota = selectUserQuota;
const selectConversationQuota = (state) => state.subscriptionPlan.quota.conversations;
exports.selectConversationQuota = selectConversationQuota;
