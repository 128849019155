"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const useMembership_1 = require("../../hooks/useMembership");
const GradientButton_1 = require("../../component/GradientButton");
const AppText_1 = require("../AppText");
const AppIcons_1 = require("../AppIcons");
const colors_1 = require("../../common/colors");
function UpgradeBanner() {
    const { handleMembershipAction } = (0, useMembership_1.useMembership)();
    return ((0, jsx_runtime_1.jsxs)(native_base_1.VStack, { style: {
            bottom: 0,
            backgroundColor: colors_1.Colors.DARK_600,
            borderTopRightRadius: 24,
            borderTopLeftRadius: 24,
            height: 260,
            zIndex: 1,
            paddingTop: 15,
            paddingHorizontal: 5,
            alignItems: "center",
            width: "100%",
        }, space: 2, children: [(0, jsx_runtime_1.jsx)(AppIcons_1.UnlockMessage, {}), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.DARK_100, fontSize: 18, textAlign: "center", children: "Your daily messages are up! Come back tomorrow for more chats." }), (0, jsx_runtime_1.jsx)(GradientButton_1.GradientButton, { text: "Upgrade for Continuous Chats!", fullWidth: false, onPress: handleMembershipAction })] }));
}
exports.default = UpgradeBanner;
